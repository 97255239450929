:root {
  --mktg-accent-primary: #7ee787;
  --mktg-accent-secondary: #aff5b4;
  --mktg-orange-primary: #ffa28b;
  --mktg-orange-secondary: #ffc2b2;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

body {
  background-color: #0d1117;
  color: #fff;
}

.hero-img {
  right: -1050px;
  max-width: calc(230vw + 1670px);
  position: absolute;
  top: 0px;
  z-index: -1;
}
.form-input {
  width: 100%;
  display: flex;
  column-gap: 10px;
  margin-bottom: 20px;
}
.home-campaign-git-line {
  background: linear-gradient(#d2a8ff, #a371f7 10%, #196c2e 70%, #2ea043 80%, #56d364);
  width: 3px;
  min-height: 240px;;
}
.events-none {
  pointer-events: none;
}
.d-flex {
  display: flex !important;
}

.position-relative {
  position: relative !important;
}
.flex-column {
  flex-direction: column !important;
}
.col-1 {
  width: 8.33333333%;
}
.col-12 {
  width: 91%;
}
.home-campaign-glowing-icon, .home-campaign-glowing-icon-glow {
  transform: translateZ(0);
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-3 {
  margin-top: var(--base-size-16, 16px) !important;
  margin-bottom: var(--base-size-16, 16px) !important;
}

.z-1 {
  z-index: 1 !important;
}
.d-inline-block {
  display: inline-block !important;
}
.position-relative {
  position: relative !important;
}

.z-n1 {
  z-index: -1 !important;
}

.height-full {
  height: 100% !important;
}
.width-full {
  width: 100% !important;
}
.left-0 {
  left: 0 !important;
}
.top-0 {
  top: 0 !important;
}
.position-absolute {
  position: absolute !important;
}
.glow-2 {
  background-color: #dd7df7;
  filter: blur(17px);
}
.octicon {
  display: inline-block;
  overflow: visible !important;
  vertical-align: text-bottom;
  fill: currentColor;
}
.home-campaign-lines-hero {
  left: calc(50% - 14px);
  width: 437px;
  height: 643px;
}

.home-drone.build-in-animate {
  animation-play-state: running;
}

.home-drone {
  position: fixed;
  animation: 10s ease infinite float-y paused;
}
.events-none {
  pointer-events: none;
}
.height-auto {
  height: auto !important;
}
.top-12 {
  top: 118px !important;
}
.td0 {
  transition-delay: 0ms;
}
.td200 {
  transition-delay: 200ms;
}
.td300 {
  transition-delay: 300ms;
}
.right-0 {
  right: 90px !important;
}

.col-2 {
  width: 16.99999999%;
}

@keyframes float-y {
  0%, 100% {
    transform: translateY(0);
  }

50% {
    transform: translateY(-40px);
  }
}


.text-left {
  text-align: left !important;
}


.pt-12 {
  padding-top: var(--base-size-128, 168px) !important;
}
.pl-2 {
  padding-left: var(--base-size-8, 8px) !important;
}
.mt-12 {
  margin-top: var(--base-size-128, 128px) !important;
}
.col-11 {
  width: 91.66666663%;
}

.hero-page {
  padding-top: 68px;;
}


.h0-mktg, .h1-mktg, .h2-mktg, .h3-mktg, .h4-mktg, .h5-mktg {
  font-family: "Mona Sans","Mona Sans Header Fallback",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-feature-settings: unset;
}

.h0-mktg {
  font-weight: 600 !important;
  letter-spacing: -0.03em;
}
.z-2 {
  z-index: 2 !important;
}
.h0-mktg {
  font-size: 72px !important;
  line-height: 76px !important;
}
.f0-mktg, .f1-mktg, .f2-mktg, .f3-mktg, .f4-mktg, .f5-mktg, .f6-mktg {
  font-feature-settings: unset;
}
.z-1 {
  z-index: 1 !important;
}
.f1-mktg {
  font-size: 28px !important;
  line-height: 40px !important;
  letter-spacing: -0.01em !important;
}

.f1-mktg {
  font-size: 24px !important;
  line-height: 32px !important;
  font-weight: 600;
}
.f0-mktg, .f1-mktg, .f2-mktg, .f3-mktg, .f4-mktg, .f5-mktg, .f6-mktg {
  font-family: "Mona Sans","Mona Sans Fallback",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-feature-settings: "ss02" on,"ss01" on;
  font-weight: var(--base-text-weight-normal, 400);
}
.text-normal {
  font-weight: var(--base-text-weight-normal, 400) !important;
}
.mb-md-7 {
  margin-bottom: var(--base-size-48, 48px) !important;
}
.mb-md-4 {
  margin-bottom: var(--base-size-24, 24px) !important;
}
.mb-3 {
  margin-bottom: var(--base-size-16, 16px) !important;
}
.position-relative {
  position: relative !important;
}
.color-fg-muted {
  color: #7d8591;
}
.col-11 {
  width: 91.66666663%;
}

.col-8 {
  width: 65%;;
}

.flex-items-center {
  align-items: center !important;
}

.mb-4 {
  margin-bottom: var(--base-size-24, 24px) !important;
  margin-top: var(--base-size-24, 24px) !important;
}

.glow-3 {
  background-color: var(--mktg-accent-primary);
    filter: blur(18px);
}
.glow-4 {
  background-color: var(--mktg-orange-primary);
  filter: blur(18px);
}
.line-color-2 {
  background: linear-gradient(#ffd6cc, #ec6547, #ec6547, #0d1117) !important;
}
.text-orange-primary {
  color: var(--mktg-orange-primary);
}

.h5-mktg {
  font-family: "Mona Sans","Mona Sans Header Fallback",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-feature-settings: unset;
    color: #7d8591;

}

.h3-mktg {
  font-size: 48px !important;
    line-height: 52px !important;
    letter-spacing: -0.03em !important;
    font-family: "Mona Sans","Mona Sans Header Fallback",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-feature-settings: unset;
    color: #7d8591;
    font-weight: 500 !important;
}

.text-accent-primary {
  color: var(--mktg-accent-primary);
} 

.feature-image {
  border: 1px solid #7d8591;;
  border-radius: 8px;
  margin-left: -90px;
  max-width: 110%;;
  margin-bottom: 90px;;
  margin-top: 30px;;
  box-shadow: 0 0 0 1px rgba(0,0,0,.5), 0 70px 65px rgba(0,0,0,.18), 0 30px 30px rgba(0,0,0,.14), 0 15px 15px rgba(0,0,0,.12), 0 10px 8px rgba(0,0,0,.1), 0 4px 4px rgba(0,0,0,.08), 0 2px 2px rgba(0,0,0,.06) !important
}
.product {
  border: 1px solid #7d8591;;
  border-radius: 8px;
  margin-top: 30px;;
  padding-bottom: 20px;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(0,0,0,.5), 0 70px 65px rgba(0,0,0,.18), 0 30px 30px rgba(0,0,0,.14), 0 15px 15px rgba(0,0,0,.12), 0 10px 8px rgba(0,0,0,.1), 0 4px 4px rgba(0,0,0,.08), 0 2px 2px rgba(0,0,0,.06) !important
}
.product.product-sin-badge {
  padding-bottom: 0px;
}
.product-image img {
  max-width: 100%;
}
.product-description {
  padding:  12px;
  color: #7d8591;
  font-size:12px;
}
.product-tags {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  row-gap: 8px;
  flex-wrap: wrap;
}
.product-tags__tag {
  border: 1px solid #7d8591;;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 8px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
}

.my-md-12 {
  margin-top: var(--base-size-128, 128px) !important;
  margin-bottom: var(--base-size-128, 128px) !important;
} 
.f4-mktg {
  font-size: 16px !important;
  line-height: 24px !important;
  color: #7d8591;
}

#detail {
  /* max-width: 100%;
  overflow: hidden;
  position: relative;
  top: -130px;
  z-index: -1; */
}
.logo-image {
  height: 150px;;
}
.badge-image {
  max-width: 23%;
  margin-right: 8px;
  margin-top: 20px;
  margin-bottom: 20px;;
  z-index: 999;
  position: relative;
  border-radius: 8px;
}


.color-fg-default {
    color: #fff;
    max-width: 700px;
    text-align: center;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 50px !important;
}

.p-final, .description {
  max-width: 750px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 50px !important;
  font-size: 24px !important;
  color: #7d8591;
}
.description{
  margin-top: 100px;;
}
.description-2 {
  max-width: 750px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 50px !important;
  font-size: 24px !important;
  color: #7d8591;
}

.main-button {
  margin-bottom: 200px;
  max-width: 300px;
}

.product-tags__tag--sustainable {
  background-color: #7ee787;
  border-color: #7ee787;
  color: #000
}

.loading {
  width: 100%;
  height: 100%;
  background-color: #0d1117;
  z-index:9999;
  position: fixed;
  top: 0;
  left:0;
  display: flex;
  justify-content: center;
  align-content: center;
}

.loading img {
  width: 200px;
  margin-top: 200px;
  animation: spin 1s linear infinite;
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
